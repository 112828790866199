<template>
  <div class="error-page">
    <div class="container small">
      <div class="logo-image">
        <img
          src="@/assets/img/logo/logo-kvadrat-lilla.svg"
          alt="Aros Forsikring GS"
        />
      </div>
      <h1 class="page-headline margin-bottom-30">{{ headline }}</h1>
      <div v-if="body" v-html="body" class="text-align-center font-size-18" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      standardHeadline: "Øv!",
      standardBody: "Der er desværre sket en fejl. Prøv igen eller kontakt os på tlf. <a href='tel:70602402'>7060 2402</a>.",
    }
  },
  computed: {
    headline() {
      if (this.$route.query.p == "M90") {
        switch(this.$route.query.r) {
          case 2:
          case "2":
            return "Linket er desværre udløbet"
          case 3:
          case "3":
            return "Du har allerede opdateret din forsikring"
          default:
            return this.standardHeadline;
        }
      }
      return this.standardHeadline;
    },
    body() {
      if (this.$route.query.p == "M90") {
        switch(this.$route.query.r) {
          case 2:
          case "2":
            return "Dette link er desværre ikke længere aktivt.<br/><br/>Hvis du ikke har fået opdateret din bilforsikring, har du automatisk fået udstedt en kasko- og ansvarsforsikring.<br/><br/>Har du spørgsmål til din bilforsikring, eller har du brug for rådgivning, er du altid velkommen til at kontakte os på tlf. <a href='tel:7060 2402'>7060 2402</a>."
          case 3:
          case "3":
            return "Vi kan se, at du allerede har opdateret din forsikring.<br/><br/>Ønsker du at tilføje tilvalgsforsikringer, eller har du brug for rådgivning, er du altid velkommen til at kontakte os på tlf. <a href='tel:70602402'>7060 2402</a>."
          default:
            return this.standardBody;
        }
      }
      return this.standardBody;
    }
  }
};
</script>
<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-grow: 1;
}
.logo-image {
  max-width: 100px;
  margin: 0 auto 4rem;
}
</style>
